@import "@scss/base";

.select {
  $formfield: ".select";
  position: relative;
  display: block;
  margin: rem(2) 0 rem(10); // COMMENT: Calculated spacing = 2 + 7 + 3*2 = 15
  padding: rem(3) 0; // COMMENT: Give some room for the label's minus position when active/focussed

  &.is-invalid {
    #{$formfield}__selector {
      color: var(--c-s-error2);
      border-color: var(--c-s-error2);
    }
  }

  &.is-valid {
    #{$formfield}__selector {
      color: var(--c-b-grey1);
    }

    &:after {
      opacity: 1;
    }
  }

  &.is-disabled {
    #{$formfield}__selector {
      background-color: darken(white, 5%);
    }
  }

  &__selector {
    @include fontStyle(paragraph-2);

    @include mq($until: vp3) {
      font-size: rem(16);
    }

    -webkit-border-radius: 0;
    width: 100%;
    padding: rem(18) rem(18) rem(16) rem(18);
    line-height: normal;
    border: rem(1) solid var(--c-b-grey3);
    outline: none;
    background-color: transparent;
    color: var(--c-b-grey3);
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;

    &::-ms-expand {
      display: none;
    }

    &:focus,
    &:active {
      border-color: var(--c-b-grey2);
    }

    &:focus {
      border: rem(1) solid var(--c-b-grey1);
      color: var(--c-b-grey1);
    }
  }

  &:focus-within {
    #{$formfield}__label {
      color: var(--c-b-grey1);
      font-size: rem(11);
    }
  }

  &::after {
    content: url("../../../assets/svg/chevron-down.svg");
    opacity: 0.3;
    position: absolute;
    top: rem(32);
    transform: translate(-50%, -50%);
    right: rem(15);
    color: var(--c-b-grey2);
    pointer-events: none;
  }

  &__label {
    // COMMENT: This label functions as the usual placeholder-attr
    @include fontStyle(paragraph-2);
    position: absolute;
    pointer-events: none;
    left: rem(18);
    transform: translate(0, -50%);
    transition: all 0.25s ease;
    line-height: 1;
    color: var(--c-b-grey3);
    top: rem(4);
    background-color: var(--c-b-white);
    padding: 0 rem(2);
    z-index: 1;
    max-width: 85%;
    font-size: rem(11);
  }

  &__message {
    @include fontStyle(paragraph-2);
    margin-top: rem(5);
    color: var(--c-s-error2);
  }

  &--sort {
    display: flex;
    align-items: center;
    width: 100%;

    #{$formfield}__selector {
      padding: 0;
      margin-left: rem(5);
      width: auto;
      border: none;
      text-align: right;

      white-space: nowrap;
    }

    #{$formfield}__sort-text {
      white-space: nowrap;
      margin-right: 0.5em;
    }

    select {
      color: var(--c-b-grey3);
      text-align-last: right;
      text-decoration: underline;
    }

    option {
      direction: rtl;
    }

    &::after {
      content: none;
    }
  }
}